import "./App.css";
import React, { lazy, Suspense } from "react";
// import GetStarted from "./Pages/GetStarted";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./hook/Loader/Loader";
// import ApplyNowForm from "./hook/ApplyNowForm/ApplyNowForm";
// import BlogContent from "./Pages/BlogContent";
// import PAF from "./Pages/PAF";
// import LCA from "./Pages/LCA";
// import TechBlog from "./Pages/TechBlog";
// import Expertise from "./Pages/Expertise";
// import TechStack from "./Pages/TechStack";
// import LetsTalk from "./Pages/LetsTalk";
// import JoinOurTeam from "./Pages/JoinOurTeam/JoinOurTeam";
// import JobDetails from "./Pages/JoinOurTeam/JobDetails";
// import Products from "./Pages/Products";
const JoinOurTeam = lazy(() => import("./Pages/JoinOurTeam/JoinOurTeam"));
const JobDetails = lazy(() => import("./Pages/JoinOurTeam/JobDetails"));
const LetsTalk = lazy(() => import("./Pages/LetsTalk"));
const TechStack = lazy(() => import("./Pages/TechStack"));
const Products = lazy(() => import("./Pages/Products"));
const Expertise = lazy(() => import("./Pages/Expertise"));
const TechBlog = lazy(() => import("./Pages/TechBlog"));
const GetStarted = lazy(() => import("./Pages/GetStarted"));
const LCA = lazy(() => import("./Pages/LCA"));
const PAF = lazy(() => import("./Pages/PAF"));
const BlogContent = lazy(() => import("./Pages/BlogContent"));
const ApplyJobForm = lazy(() => import("./Pages/JoinOurTeam/ApplyJobForm"));

function App() {
  return (
  
    // <BrowserRouter>
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <Routes>
        {/* <Route path="/" element={<Loader />}></Route> */}
        <Route path="/" element={<GetStarted />}></Route>
        <Route path="/expertise" element={<Expertise />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/techstack" element={<TechStack />}></Route>
        <Route path="/techblog" element={<TechBlog />}></Route>
        <Route path="/joinourteam" element={<JoinOurTeam />}></Route>
        <Route path="/letstalk" element={<LetsTalk />}></Route>
        <Route path="/LCA" element={<LCA />}></Route>
        <Route path="/PAF" element={<PAF />}></Route>
        <Route path="/jobdetails" element={<JobDetails />} />
        <Route path="/blogcontent" element={<BlogContent />} />
        <Route path="applynowform" element={<ApplyJobForm />} />
      </Routes>
    </Suspense>
    // </BrowserRouter>
  );
}

export default App;
