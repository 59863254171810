import React from "react";
import { Oval } from "react-loader-spinner";
import styles from "./Loader.module.css"; // Import CSS module
import logo from "../../assets/psicon.png"; // Adjust the path to your logo image

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#fa840e"
          secondaryColor="#fa840e"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperclassName=""
        />
      </div>
    </div>
  );
};

export default Loader;
